:root{
    --color-1: #f1f1f1;
    --color-2: #fff;
    //--color-3: #A668D5;
    --color-3: #464743;
    //--color-4: #fff4e3;
    --color-4: #544404;
    --color-5: #048fa1;
    --color-6: var(--color-3);
    --color-7: transparent;
    --color-8: #fac87c;
    --color-9: #ececd4;
    --color-10: var(--color-background);
    --color-11: #f4b693;
    --color-background: #fff;
    --color-13: #048fa1;
    --color-14: #4B2C2B;
    --color-15: #2c3f53;
    --color-16: #dc7c3c;
    --color-white: #fff;
}