@import "./vars";


@mixin scrollbars($size, $foreground-color, $foreground-color-hover, $background-color: mix($foreground-color, white,  50%), $radius: 0) {
}

/*Border Component END*/
.ya-share2__container_mobile .ya-share2__badge{
  border-radius: 0!important;
}
.ya-share2__link{
  margin-right: 15px;
  margin-left: 15px;
}
.ya-share2__item_more .ya-share2__badge, .ya-share2__item_copy .ya-share2__badge{
  background: var(--color-9) !important;
}


html,
body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
}

.nkmsite{
  display: flex;
  justify-content: space-between;
}





.nkmsong-aplhabet {
  margin-bottom: 30px;
	justify-content: center;
	padding: 25px;
  display: flex;
  flex-wrap: wrap;
  background: var(--color-8);
  border-radius: 30px;
	//background: var(--color-1);
  &__link{
    font-weight: 700;
    color: var(--color-5);
    font-size: 17px;
    margin-right: 10px;
    margin-bottom: 5px;
    position: relative;
    &:hover{
      text-decoration: underline;
    }
  }
}

.mobile-nav__button {
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  &-icon{
      display: block;
      position: relative;
      width: 50px;
      height: 4px;
      border-radius: 10px;
      background-color: var(--color-5);
  }
}

.nkmcontainer {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}

.nkmsotial-network-vk{
  display: flex;
  flex-direction: column-reverse;
}

.nkmheader{
  margin-top: 35px;
  &_menu{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 30px;
  }
  &_info{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_logo-link{
    font-size: 44px;
    font-weight: 700;
    color: var(--color-13)
  }
  &_input-content{
    box-sizing: border-box;
    border: none;
    border-radius: 30px;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 700;
    height: 72px;
    color: var(--color-13);
    width: 100%;
    border: 1px solid var(--color-13);
    &::placeholder{
      font-weight: 700;
      color: var(--color-13);
    }
  }
  &_find{
    width: 40%;
    margin-left: -4px;
  }
  &_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
  }
}



#nkmcontent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nkmwrapper{
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  width: 65%;
}

.nkmpopular{
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  &_title{
    word-wrap: break-word;
    overflow: hidden;
    font-weight: 700;
    color: var(--color-13);
    margin: 0 20px 0 0;
  }
  &_logo{
    width: 45px;
    height: 45px;
    fill: var(--color-13);
  }
}



.pages{
  color: var(--color-13);
  padding-top: 15px;
}

.nkmresult-of-search:hover{
  background-color: var(--color-13); 
  .nkmresult-of-search_link{
    color: #fff;
  }
}

.nkmresult-of-search{
  padding: 15px 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    background-color: var(--color-5);
  }
  &_link{
    font-size: 20px;
    color: var(--color-5);
    font-weight: 700;
    margin-left: 20px;
  }
  &_box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.nkmresults-info{
  display: flex;
  flex-direction: column;
  p{
    font-size: 30px;
    margin: 0 auto 30px ;
    text-align: center;
  }
  &__card_artist, &__card_tracklink{
    overflow: hidden;
    max-height: 18px;
  }
  &__card_tracklink{
    margin-left: 5px;
  }
  &__card{
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    position: relative;
    transition: all .3s ease;
    padding: 10px 0;
    &_artist{
      margin-right: 5px;
    }
    &_like{
      height: 19px;
      svg{
      fill: var(--color-13);
      width: 15px;
      height: 15px;
      }
    }
    &:last-child {
      margin-bottom: 14px;
    }
    &:hover {
     &::after{
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--color-13);
      bottom: 0;
     }
    }
  }
  &__card.pausing a.nkmresults-info__card_link::before{
    content: url('../images/startmin.svg');
  }
  &__card.plays a.nkmresults-info__card_link::before{
    content: url('../images/pausemin.svg');
  }

  &__card .start {
    cursor: pointer;
    font-size: 22px;
    margin-right: 10px;
    color: var(--color-white);
    margin-left: 0;
} 
  &__card-subtext{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__card_name{
    font-size: 16px;
    margin: 0 0 0 10px;
    line-height: normal;
    width: 100%;
    flex-direction: column;
    display: flex;
    height: 55px;
    font-weight: 700;
  }
  &__card_time{
    margin-right: 20px;
    font-size: 16px;
    color: var(--color-5);
    display: flex;
    align-items: center;
  }
  &__card_share{
    right: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 26px;
    i{
      font-size: 25px;
      color: var(--color-5);
      transition: all .5s;
      right: 5px;
    }
  }
  &__card_artist, &__card_tracklink, &__card_space{
    color: var(--color-5);
  }
  &__card_download{
    cursor: pointer;
    right: 1px;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 0;
  }
  &__card_link{
    cursor: pointer;
    margin-left: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: var(--color-white);

    &::before {
      position: relative;
      display: inline-block;
      content: url('../images/startmin.svg');
      width: 60px;
      height: 60px;
    }
  }
}



.nkmmusic{
  background-color: var(--color-8) !important;
  padding: 25px 0 0 25px;
  border-radius: 30px;
  margin-bottom: 30px;
  &__content{
    margin-bottom: 15px;
    display: flex;
  }
}
.nkmmelodious__link{
  margin: 0 20px 0 0;
  font-weight: 700;
  color: var(--color-14);
  font-size: 35px;
}

.glyphicon-time{
  font-size: 25px;
  margin-right: 7px;
}

.nkmmelodious{
  &__genre{
    fill: var(--color-8);
    width: 40px;
    height: 40px;
  }
  &__list{
    display: flex;
  }
  &__list a:first-child::before{
    content: none;
  }
  &__list a{
    margin-left: 30px;
    color: var(--color-14);
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 22px;
    &::before{
      content: "";
      height: 100%;
      width: 1px;
      position: absolute;
      left: -15px;
      background: var(--color-14);
    }
    &:hover{
      color: var(--color-13);
    }
  }
}

.submit {
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
  color: var(--color-13);
  transition: all 0.2s ease;
  border: none;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;

  svg {
    width: 35px;
    height: 35px;
    transition: all 0.5s;
    fill: var(--color-13);
  }
}

.dur {
  margin-right: 0;
  font-weight: 400;
  position: relative;
  line-height: normal;
}

.title {
  height: 22px;
  white-space: inherit;
  span{
    color: var(--color-13);
    font-weight: 700;
    font-size: 18px;
  }
  align-items: center;
  font-weight: 400;
  flex-wrap: wrap;
  margin-bottom: 5px;
  overflow: hidden;
}


.nkmfooter{
  &_wrapper {
    position: relative;
    color: var(--color-13);
  }
  &_copy{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.nkmplayer-link{
  font-size: 30px;
  color: #fff;
}

.nkmsearch-some_track-title{
  word-wrap: break-word;
}

.nkmsearch-some{
  margin-bottom: 10px;
  padding: 0 30px;
  &_heading{
    display: flex;
    align-items: center;
    font-weight: 700;
    color: var(--color-14);
    margin: 15px 0;
    font-size: 35px;
    svg{
      fill: var(--color-14);
      width: 40px;
      height: 40px;
      margin-left: 20px;
    }
  }
  &_img{
    margin-right: 15px;
    font-size: 25px;
  }
  &_content{
    display: flex;
    flex-wrap: wrap;
  }
  &_track{
    box-sizing: border-box;
    color: var(--color-14);
    font-weight: 700;
    margin-bottom: 15px;
    padding: 15px;
    background: var(--color-13);
    border-radius: 30px;
    margin-right: 15px;
    font-size: 18px;
    &:hover{
      outline: 1px solid var(--color-13);
      background: var(--color-9);
      .nkmsearch-some_track-title{
        color: var(--color-13)
      }
    }
  }
  &__track-img{
    height: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;  
    color: var(--color-white);
    i{
      font-size: 30px;
    }
  }
  &__track-title{
    font-size: 20px;
    font-weight: 700;
  }
}

.nkmdescription{
  font-size: 24px;
  margin-bottom: 30px;
  &_content{
    color: var(--color-14);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  &_icon{
    font-size: 25px;
    margin-right: 10px;
  }
  &_title{
    text-align: center;
    position: relative;
    } 
  }

.nkmcopyright_box{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  margin-bottom: 170px;
  margin-top: 15px;
  &-logo{
    padding: 0 10px;
  }
  &-mail{
    color: var(--color-14);
    display: flex;
    align-items: center;
    font-size: 30px;
    a{
      color: var(--color-14);
      margin-left: 8px;
      font-weight: 700;
    }
  }
  &-link{
    color: var(--color-14);
  }
}

.ya-share2__list.ya-share2__list_direction_horizontal{
  flex-wrap: wrap;
}
.nkmsocial-box {
  padding-bottom: 30px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nkmsocial-box--btnBox {
  flex-wrap: wrap;
  position: relative;
  padding: 5px;
}

.about{
  &__inner{
    margin-bottom: 20px;
    padding: 5px;
    color: var(--color-2);
    
  }
}

.boxline{
  margin: 10px 0;
  &__top{
    margin-bottom: 10px;
    padding-bottom: 10px;
    border: 1px solid var(--color-2);
    border-radius: 15px;
    padding: 15px;
  }
  &__top p{
    margin-bottom: 10px;
    font-size: 16px;
  }
  &__bottom{
    font-size: 20px;
    font-weight: 700;
    color: var(--color-white);
    text-align: center;
  }
}

 .nkmsotial-network-vk {
  &_btn{
    border-radius: 20px;
    height: 65px;
    border: 2px solid var(--color-14) ;
    color: var(--color-14);
    background-color: #00ffcb;
    width: 50%;
    font-size: 22px;
    padding-left: 20px;
    font-weight: 700;
  }
    &::placeholder{
      color: var(--color-14)
    }
  &_link{
    border-radius: 30px !important;
    font-size: 16px;
    border: 1px solid var(--color-14);
    border-radius: 0;
    color: var(--color-14);
    position: relative;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: color 150ms ease-in-out;
    transition: color 150ms ease-in-out;
    font-weight: 700;
    height: 65px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: #00ffcb;
      z-index: -1;
      -webkit-transition: width 150ms ease-in-out;
      transition: width 150ms ease-in-out;
    }
    &:hover {
      color: var(--color-14);
    }
    &:hover::after{
      width: 110%;
    }
  }
  &_input{
    margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  &_content{
      line-height: 1.8; 
      font-size: 20px;
      padding: 10px;
      color: var(--color-5);
  }
}

.active__selection::marker,.active__genre::marker{
  color: var(--color-13);
}
.active__selection,.active__genre{
  font-weight: 700;
  color: var(--color-13);
  font-size: 18px;
  margin-bottom: 5px;
} 

#genre{
  padding: 25px 0 0;
  list-style-type: none;
  position: relative;
}

.nkmgenre{
  &-list{
    display: flex;
    flex-wrap: wrap;
  }
  &-item{
    background: var(--color-13);
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 15px;
    list-style-type: none;
    color: var(--color-14);
    margin-right: 15px;
    &_link{
      font-size: 16px;
      color: var(--color-14);
      font-weight: 700;
    }
    &:hover{
      background-color: var(--color-9);
      .nkmgenre-item_link{
        color: var(--color-13);
      }
      .nkm--genre-item_logo{
        color: var(--color-4);
      }
    }
  }
  &-item_logo{
    margin-right: 15px;
    font-size: 25px;
    color: var(--color-14);
  }
}
.active__selection{
  list-style: none;
}

#trackAjax {
  padding-top: 15px;
  padding-bottom: 10px;
  z-index: 1;
  padding: 15px;
}

#searchform {
  position: relative;
  border: 6px solid #fff;
  &:hover{
    border: 6px solid #00ffcb;
    border-radius: 35px;
  }
}


.glyphicon {
  top: 0;
  vertical-align: text-top;
}

.pleer {
    flex-direction: column;
    z-index: 99999;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin: 0 auto;
    padding-bottom: 20px;
    box-shadow: 0 2px 5px 0rgba(0,0,0,.16),0 2px 10px 0rgba(0,0,0,.12);
    background:#78e2d5;
    transition: all .6s ease;
}

.volume {
  transform: rotate(180deg);
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  display: inline-block;
  width: 18px;
  height: 107px;
  background: #78e2d5;
  vertical-align: top;
  right: 8px;
  bottom: 25px;
  margin-top: 0;
  border-radius: 30px;
  position: absolute;
  z-index: 202;
  border: 1px solid var(--color-13);

  .volume__line {
    position: absolute;
    top: 0;
    cursor: pointer;
    width: 100%;
    height: 20px;
    float: left;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
    background: var(--color-13);
    border-radius: 30px;
  }
}



.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

a.play {
  color: var(--color-white);
  //border: none;

  &:hover {
    text-decoration: none;
  }
}

a.pause {
  color: var(--color-white);

  &:hover {
    text-decoration: none;
  }
}


.play,.pause{
  fill:"#4B2C2B";
  width: 55px;
  height: 55px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 25px;
}
.play {
  //border-radius: 50%;
  //border: 2px solid var(--color-white);
  &::before {
    content: url('../images/start.svg');
    width: 55px;
    height: 55px;
  }
}
.pause {
  &::before {
    content: url('../images/pause.svg');
    width: 55px;
    height: 55px;
  }
}

.nkmtrack-info{
  display: flex;
}

.nkmtrack-central_left-btn{
  transform: rotate(180deg);
}

.nkmtrack-central_left-btn,
.nkmtrack-central_right-btn{
  svg{
  width: 38px;
  height: 40px;
  margin: 0 -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  fill: var(--color-14);
  }
}


.nkmtrack-central{
  width: 150px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  margin-right: 60px;
}

.progressBarContainer-playBar{
  position: relative;
  display: inline-block;
  height: 11px;
  background: var(--color-2);
  color: var(--color-2);
}

.progressBarContainer--downloadBar{
  display: block;
  height: 20px;
  width: 0;
  z-index: 200;
}

.download-bar{
  display: block;
  height: 10px;
  width: 100px;
  z-index: 201;
  border-radius: 20px;
}

.progress-bar__container-playbar {
  position: absolute;
  left: 0;
  display: inline-block;
  height: 20px;
  background: var(--color-13);
  color: var(--color-2);
  align-items: center;
  //border-radius: 10px;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: var(--color-white);
    border: 2px solid #061161;
    display: none;
  }
}

.track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px 0;
}

.track__track-timebox {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
  margin-bottom: 15px;
  background: #78e2d5;
  &::after{
    position: absolute;
    bottom: 0;
    content: "";
    height: 3px;
    width: 100%;
    background: var(--color-13);
  }
  &::before{
    position: absolute;
    top: 0;
    content: "";
    height: 3px;
    width: 100%;
    background: var(--color-13);
  }
}

.progress-bar__container {
  align-items: center;
  cursor: pointer;
  transition: height linear 0.3s;
  height: 20px;
  left: 0;
  color: #000;
  font-size: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.nkmzero-time {
  font-size: 16px;
  margin-left: 15px;
  color: var(--color-14);
  z-index: 202;
}

.timeBox {
  margin-right: 15px;
  font-size: 16px;
  color: var(--color-14);
  z-index: 202;
}

.nkmplayer-title_heading {
  margin-right: 30px;
  display: block;
  margin-left: 0;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  max-height: 50px;
  color: var(--color-14);
  text-overflow: ellipsis;
}

.nkmmute-box {
  display: flex;
  height: 100%;
  .SocialBox{
    min-height: 36px;
    justify-content: flex-start;
    .SocialBox--btnBox{
      border-color: var(--color-white);
    }
  }

  &__muteUnmute{
    position: relative;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.loaded {
  display: none;
}

.uil {
  cursor: pointer;
  transition: all 0.2s ease;
  //background-color: var(--color-7);

  &:hover {
    transition: all 1.2s ease;
  }
}

a.uil {
  color: var(--color-white);
  font-size: 25px;
}

.uil.pause {
  //background-color: rgba(6, 23, 0, 0.6);
  //border: 2px solid var(--color-1);

  &:hover {
    background-color: transparent;
    //border: 2px solid var(--color-2);
  }
}

.uil.playBox--rightBtn {
  border-left: none;
  border-bottom: none;
}

.uil.playBox--LeftBtn {
  border-right: none;
  border-top: none;
  & i{
    font-size: 25px;
  }
}

.nkmtime{
  font-weight: 700;
  display: flex;
}

.nkmtrack__leftWrapper {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .player__Collapse{
    display: none;
  }
}

.nkmplayerTime__box{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.nkmplayer-title{
  display: flex;
  align-items: center;
}

.nkmplayer-title_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  svg {
    width: 40px;
    height: 30px;
    fill: var(--color-14);
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  width: 40px;
  height: 24px;
  margin-right: 10px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &:hover {
    opacity: 1;
  }
}




.mute,
.unmute{
  svg{
    position: relative;
    display: inline-block;
    width: 22px;
    height: 22px;
    fill: var(--color-14);
  }
}



#GeneralBox,
#toTop{
  display: none;
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--color-3);
    }

    &:hover {
      background-color: var(--color-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--color-3);
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}




/*SHAREBOX*/
span{
  &.shareblock{
    border-radius: 15px;
    z-index: 10000;
    width: 500px;
    display: block;
    background-color: var(--color-13);
    position: fixed;
    top: 50%;
    left: 20%;
    margin: -100px 0 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    border: 1px solid var(--color-2);
    b {
      border: 4px solid var(--color-13);
      position: absolute;
      top: -20px;
      right: -20px;
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      margin-bottom: -24px;
      background-color: #fff;
      cursor: pointer;
      border-radius: 30px;
      line-height: 40px;
      font-size: 25px;
    }
    h1 {
      padding: 10px 15px;
      margin: 0;
      background: var(--color-13);
      font-family: sans-serif;
      font-size: 25px;
      font-weight: 400;
      display: block;
      color: var(--color-white);
      border-bottom: 4px solid var(--color-white);
    }
    span {
      width: 400px;
      justify-content: space-between;
      padding: 25px 0;    
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
    }
    a {
      color: var(--color-white);
      font-size: 22px;
      background: url(../images/share.png) 10px 0 no-repeat;
      margin: 10px 10px 0 0;
      padding: 0 10px 0 25px;
      &.vk {
        background-position: 5px 0;
      }
      &.tw {
        background-position: 5px -60px;
      }
      &.ok {
        background-position: 5px -90px;
      }
      &.mm {
        background-position: 5px -120px;
      }
      &.gp {
        background-position: 5px -150px;
      }
    }
  }
}

.nkmcompilation{
  &_link{
    color: var(--color-14);
  }
  padding: 0;
  &_list{
    background: var(--color-13);
    padding: 15px;
    margin-bottom: 15px;
    list-style-type: none;
    margin-right: 15px;
    &:hover{
      background: var(--color-9);
      .nkmcompilation_link{
        color: var(--color-13);
      }
    }
  }
}


.sit--vk__btn-svg{
  display: none;
}

.buttons {
  text-align: center;
  border-radius:30px;
}

.nkmvk {
  min-width: 300px;
  min-height: 60px;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: var(--color-14);
  background: #4FD1C5;
background: linear-gradient(90deg, rgba(129,230,217,1) 0%, rgba(79,209,197,1) 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(79,209,197,.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;

  &::before {
    content: '';
    border-radius: 1000px;
    min-width: calc(300px + 12px);
    min-height: calc(60px + 12px);
    border: 6px solid #00FFCB;
    box-shadow: 0 0 60px rgba(0,255,203,.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
}

  &:hover, &:focus {
    color: #313133;
    transform: translateY(-6px);
  }

  &:hover::before, &:focus::before {
    opacity: 1;
  }

  &::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 6px solid #00FFCB;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
  }

  &:hover::after, &:focus::after {
    animation: none;
    display: none;
  }

  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }
}

.nkmactive__selection{
  &-content{
  display: flex;
  &:hover{
    & a{
      color: var(--color-13) !important;
    }
    .nkmactive__selection-like{
      fill: var(--color-13);
    }
  }
  a{
    color: var(--color-14);
  }
}
  &-like{
    fill: var(--color-14);
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
}

.nkmsidebar{
  &_list{
    padding: 20px;
    background-color: var(--color-8)!important;
    padding: 25px 0 0 25px;
    border-radius: 30px;
    margin-bottom: 30px;
  }
  margin-right: 30px;
  width: 35%;
  & ul{
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 700;
    padding-left: 0;
  }
  .nkmsidebar-header {
    border-radius: 15px;
    padding: 20px 0 10px;
    background: var(--color-10);
    &_logo{
      a{
        color: var(--color-white);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  ul.components {
    padding: 20px 0;
  }
  ul {
    p {
      color: var(--color-white);
      padding: 10px;
      font-size: 18px;
    }
  
  }
  a[aria-expanded="true"] {
    position: relative;
    font-size: 35px;
  }
  a[data-toggle="collapse"] {
    position: relative;
  }
  a.article {
    color: var(--color-white) !important;
    &:hover {
      background: var(--color-10) !important;
      color: var(--color-white) !important;
    }
  }

  #pageSubmenu2,
  #pageSubmenu1 {
    overflow: auto;
    @include scrollbars(10px, var(--color-2), var(--color-3), var(--color-11), 0);
  }
}

#oframepleer{display:none!important;} 
#pleer{height:auto!important;}