@import './vars';


@media screen and (max-width: 1440px) {
}


@media screen and (max-width: 1024px) {
    
}
@media screen and (max-width: 992px) {
    .nkmheader_logo{
        margin-bottom: 20px;
    }
    .nkmheader_info{
        flex-direction: column;
    }
    .nkmmelodious{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .nkmmelodious__list a{
        margin-left: 0;
        font-size: 24px;
    }
    .nkmmelodious__list{
        width: 100%;
        justify-content: space-between;
    }
    }
@media screen and (max-width: 920px) {
   
}

@media screen and (max-width: 768px) {
    span.shareblock span{
        top: 20%;
        left: 0;
    }
    span.shareblock span{
        width: auto;
        justify-content: center;
    }
    span.shareblock{
        width: 300px;
    }
    .nkmpopular_title{
        font-size: 25px;
    }
    .nkmpopular_logo{
        width: 40px;
        height: 40px;
    }
    .ya-share2__popup.ya-share2__popup_mobile.ya-share2__popup_visible .ya-share2__popup-content{
        top: 30% !important;
    }
    .nkmcopyright_box-mail{
        font-size: 24px;
    }
    .nkmsearch-some_content{
        justify-content: space-between;
    }
    .nkmsearch-some_track{
        width: 48%;
        margin-right: 0;
    }
    .nkmsidebar{
        display: none;
    }
    .nkmwrapper{
        width: 100%;
    }
    .nkmmute-box__muteUnmute{
        display: none;
    }
    .nkmmelodious__list a::before{
        display: none;
    }
    .nkmheader_menu{
        padding: 0 15px;
    }
    .nkmsearch-some{
        padding: 0 15px;
    }
    .nkmfooter_copy{
        padding: 0 15px;
    }
}

@media screen and (max-width: 624px) {
    .nkmtrack-central_left-btn svg, .nkmtrack-central_right-btn svg{
        height: 30px;
        width: 32px;
        margin: 0;
    }
    .play::before{
        width: 45px;
        height: 45px;
    }
    .nkmtrack-info{
        width: 100%;
        justify-content: space-between;
    }
    .nkmtrack-central{
        width: 120px;
        margin-right: 0;
    }
    .nkmmute-box{
        display: none;
    }
    .nkmcopyright_box-mail{
        flex-direction: column;
    }
}

@media screen and (max-width: 670px) {
    .nkmmelodious__list a{
        justify-content: center;
    }
    .nkmmelodious__list{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 577px) {
    .nkmpopular_logo{
        display: none;
    }
    .nkmpopular_title{
        text-align: center;
    }
    .nkmsotial-network-vk_btn{
        width: 90%;
        margin-bottom: 15px;
    }
    .nkmsotial-network-vk_link{
        width: 100%;
    }
    .nkmsotial-network-vk_input{
        flex-direction: column;
    }
    .nkmheader_content{
        flex-direction: column-reverse;
    }
    .nkmheader_find{
        margin-left: 0;
        width: 100%;
        margin-bottom: 20px;
    }
    .nkmheader_info{
        margin-bottom: 20px;
    }
    .nkmdownload_vk{
        width: 100%;
    }
    .ya-share2__list_direction_horizontal{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .nkmvk{
        width: 100%;
        min-width: auto;
    }
    .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item{
        margin-bottom: 10px !important;
    }
    .ya-share2__list.ya-share2__list_direction_horizontal{
        display: flex !important;
        justify-content: space-between !important;
    }
    .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item{
        margin: 0 0 10px 0 !important;
    }
    .nkmsocial-box{
        padding-bottom: 0;
    }
    .nkmplayer-title_heading{
        margin-right: 0;
    }
    .nkmresults-info__card_link::before{
        width: 45px;
        height: 45px;
    }
    .nkmresults-info__card_download{
        width: 40px;
        height: 40px;
    }
    .nkmplayer-title{
        margin-left: 45px;
    }
}    


@media screen and (max-width: 510px) {
    .nkmsearch-some_heading{
        font-size: 25px;
    }
    .nkmtrack-info{
        flex-direction: column;
        align-items: center;
    }
    .nkmsearch-some_content{
        flex-direction: column;
    }
    .nkmsearch-some_track{
        width: 100%;
    }
    .nkmtrack-central{
        margin-bottom: 10px;
    }
    .nkmplayer-title{
        margin-left: 0;
        width: 100%;
    }
    .nkmcopyright_box{
        margin-bottom: 200px;
    }
}

@media screen and (max-width: 470px) {
    
}

@media screen and (max-width: 425px) {
    span.shareblock{
        left: 0;
        top: 30%;
    }
    .ya-share2__list_direction_horizontal{
        display: flex;
        flex-wrap: wrap;
    }
    .nkmvk::before{
        min-width: calc(300px + 6px);
    }
    .nkmmelodious__list{
        display: flex;
        flex-direction: column;
    }
    .nkmmelodious__list a{
        font-size: 30px;
    }
    .nkmheader_logo-link{
        font-size: 42px;
    }
}

@media screen and (max-width: 375px) {
    .nkmcopyright_box-mail{
        font-size: 20px;
    }
    .nkmsearch-some_heading{
        font-size: 20px;
    }
    .nkmsearch-some_heading svg{
        width: 30px;
        height: 30px;
    }
} 